import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../config/Config";

const initialState = {
    loading: false,
    response: null,
    error: null,
    taskList: null,
    singleTask: null,
}

export const GetTaskList = createAsyncThunk(
    "GetTaskList",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/crm/tasks/list-all?page=${body.page}&search=${body.search}&fStartDate=${body.fStartDate}&fEndDate=${body.fEndDate}&fStatus=${body.fStatus}&fSortBy=${body.fSortBy}`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response)
        }
    })

export const GetTask = createAsyncThunk("GetTask", async (id, { rejectWithValue }) => {
    try {
        const response = await instance.get(`/crm/tasks/get-task?id=${id}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error?.response);
    }
})

export const CreateTask = createAsyncThunk(
    "CreateTask",
    async (body, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append("task_assigned_to", body.task_assigned_to);
        formData.append("task_description", body.task_description);
        formData.append("task_end_at", body.task_end_at);
        formData.append("task_started_at", body.task_started_at);
        formData.append("task_title", body.task_title);
        formData.append("task_status", body.task_status);
        try {
            const response = await instance.post("/crm/tasks/create-task", formData);
            return response?.data
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)

export const UpdateTask = createAsyncThunk("UpdateTask",
    async (body, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append("task_end_at", body.data.task_end_at);
        formData.append("task_status", body.data.task_status);
        formData.append("task_title", body.data.task_title);
        formData.append("task_started_at", body.data.task_started_at);
        formData.append("task_description", body.data.task_description);
        try {
            const response = await instance.post(`/crm/tasks/update-task?id=${body?.id}`, formData);
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response)
        }

    })

export const UpdateStatus = createAsyncThunk(
    "UpdateStatus",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/crm/tasks/update-status?id=${body?.id}&status=${body?.status} `);
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response)
        }

    })

export const TaskSlice = createSlice({
    name: "TaskSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetTaskList.pending, (state) => {
            state.loading = true;
            state.response = null;
            state.singleTask = null;
        });
        builder.addCase(GetTaskList.fulfilled, (state, action) => {
            state.loading = false;
            state.taskList = action.payload;
        });
        builder.addCase(GetTaskList.rejected, (state, action) => {
            state.loading = false;
            state.status = action.status?.data?.status;
            state.error = action.payload?.data;
        });

        builder.addCase(GetTask.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetTask.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.singleTask = action.payload;
        });
        builder.addCase(GetTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(CreateTask.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(CreateTask.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.response = action.payload;
        });
        builder.addCase(CreateTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(UpdateTask.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(UpdateTask.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(UpdateTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(UpdateStatus.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(UpdateStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(UpdateStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.data;
        })



    }

})

export default TaskSlice.reducer;